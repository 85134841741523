import {
  AtlasButtonProps,
  AtlasFieldSelect,
  AtlasFieldSelectProps,
  AtlasIcon,
  AtlasLinkProps,
  AtlasLoading,
  AtlasTooltip,
} from "atlas-ds";
import classNames from "classnames";

export interface AtlasHeadingProps {
  /**
   * Le titre
   */
  children: React.ReactNode;
  /**
   * Le niveau de titre
   */
  tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  /**
   * Le niveau de hiérarchie visuelle (l par défaut)
   */
  size?: "l" | "m" | "s";
  /**
   * Une icône
   */
  iconName?: string;
  /**
   * Séparer le titre de la suite du contenu par un trait ?
   */
  separator?: boolean;
  /**
   * Le contenu d'une bulle d'information contextuelle
   */
  tooltipContent?: React.ReactNode;
  /**
   * Une information complémentaire au titre
   */
  secondaryText?: string;
  /**
   * Un bouton à placer à côté du titre
   */
  button?: React.ReactElement<AtlasButtonProps>;
  /**
   * Un lien à placer à côté du titre
   */
  link?: React.ReactElement<AtlasLinkProps>;
  /**
   * Une liste de sélection à placer à côté du titre
   */
  select?: React.ReactElement<AtlasFieldSelectProps>;
}

/**
 * Un titre. Le niveau sémantique de titre (`h1`, `h2`) et sa taille visuelle
 * sont indépendantes (propriétés `tag` et `size`).
 */
export function AtlasHeading(props: AtlasHeadingProps) {
  const Tag = props.tag;
  const select = props.select ? (
    <AtlasFieldSelect {...props.select.props} mini />
  ) : undefined;

  return (
    <div
      className={classNames(
        "atlas-heading",
        `atlas-heading--${props.size || "l"}`,
        { "atlas-heading--separator": props.separator }
      )}
    >
      {props.iconName && (
        <span className="atlas-heading__icon">
          <AtlasIcon
            name={props.iconName}
            size={props.size === "s" ? "s" : undefined}
          />
        </span>
      )}

      <div className="atlas-heading__text">
        <AtlasLoading.Loader />
        <Tag>{props.children}</Tag>
        <span className="atlas-heading__secondaryText">
          {props.secondaryText}
        </span>
      </div>

      {props.tooltipContent && (
        <div className="atlas-heading__tooltip">
          <AtlasTooltip>{props.tooltipContent}</AtlasTooltip>
        </div>
      )}

      {select && <div className="atlas-heading__cta">{select}</div>}

      {props.button && <div className="atlas-heading__cta">{props.button}</div>}

      {props.link && <div className="atlas-heading__cta">{props.link}</div>}
    </div>
  );
}
