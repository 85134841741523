import { RefObject, useEffect } from "react";

export function useCloseEvents(
  ref: RefObject<HTMLElement>,
  openerRef: RefObject<HTMLElement>,
  onClose: () => void,
  openerIsHiddenByContent?: boolean
) {
  useEffect(() => {
    document.addEventListener("click", onDocumentClickOrFocus);
    document.addEventListener("focusin", onDocumentClickOrFocus);
    document.addEventListener("keydown", onDocumentKeyDown);

    return () => {
      document.removeEventListener("click", onDocumentClickOrFocus);
      document.removeEventListener("focusin", onDocumentClickOrFocus);
      document.removeEventListener("keydown", onDocumentKeyDown);
    };
  }, []);

  const isOpen = () =>
    openerRef.current?.getAttribute("aria-expanded") === "true";

  const onDocumentClickOrFocus = (event: MouseEvent | FocusEvent) => {
    const target = event.target as HTMLElement;

    if (
      isOpen() &&
      ref.current &&
      !ref.current.contains(target) &&
      target.isConnected &&
      (target !== openerRef.current ||
        (openerIsHiddenByContent && event.type === "focusin"))
    ) {
      onClose();
    }
  };

  const onDocumentKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape" && isOpen()) {
      onClose();
      openerRef.current?.focus();
    }
  };
}
