import { AtlasFieldBaseProps, AtlasFieldInternal, AtlasIcon } from "atlas-ds";
import classNames from "classnames";

export interface AtlasFieldSelectInputProps {
  id: string;
  name: string;
  "aria-label"?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
  required?: boolean;
  "aria-invalid"?: "true" | "false";
  "aria-describedby"?: string;
  children: JSX.Element;
}

export interface AtlasFieldSelectOption {
  /**
   * La valeur de l'option
   */
  value: string;
  /**
   * Le label affiché
   */
  label: string;
}

export interface AtlasFieldSelectProps
  extends AtlasFieldBaseProps<HTMLSelectElement, AtlasFieldSelectInputProps> {
  /**
   * Les options sélectionnables
   */
  options: AtlasFieldSelectOption[];
  /**
   * La valeur de l'option sélectionnée
   */
  value?: string;
  /**
   * Ajouter une option initiale sans valeur
   */
  showEmptyOption?: boolean;
  /**
   * Le label de l'option initiale
   */
  emptyOptionLabel?: string;
  /**
   * Afficher l'élément en taille réduite. Le label sera caché.
   */
  mini?: boolean;
}

/**
 * Un champ de sélection d'options
 */
export function AtlasFieldSelect(props: AtlasFieldSelectProps) {
  const emptyOptionLabel = props.emptyOptionLabel ?? "Sélectionner";

  const inputProps: AtlasFieldSelectInputProps = {
    id: props.name,
    name: props.name,
    value: props.value,
    required: props.required ? true : undefined,
    disabled: props.disabled ? true : undefined,
    "aria-invalid": props.error ? "true" : undefined,
    "aria-describedby": props.error ? `${props.name}-error` : undefined,
    onChange: props.onChange,
    children: (
      <>
        {props.showEmptyOption && (
          <option value="">{props.disabled ? "" : emptyOptionLabel}</option>
        )}
        {props.options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </>
    ),
  };

  const select = props.inputConstructor ? (
    props.inputConstructor(inputProps)
  ) : (
    <select {...inputProps} />
  );

  return (
    <AtlasFieldInternal
      {...props}
      labelDisplay={props.mini ? "hidden" : undefined}
    >
      <div
        className={classNames("atlas-fieldSelect", {
          "atlas-fieldSelect--mini": props.mini,
        })}
      >
        {select}

        <AtlasIcon name="arrow-down" size="xs" />
      </div>
    </AtlasFieldInternal>
  );
}
