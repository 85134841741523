import classNames from "classnames";

export interface AtlasLoadingProps {
  /**
   * Le composant est-il en cours de chargement ?
   */
  loading: boolean;
  /**
   * Le contenu
   */
  children: React.ReactNode;
}

/**
 * Un composant susceptible d'être en cours de chargement.
 * Son unique rôle est d'ajouter une classe qui permettra à tous les loaders
 * contenus d'apparaître.
 * Le menu `Loading State` (barre du haut) permet, pour les autres composants,
 * de simuler leur inclusion au sein de ce composant.
 */
export function AtlasLoading(props: AtlasLoadingProps) {
  return (
    <div
      className={classNames("atlas-loading", {
        "atlas-loading--active": props.loading,
      })}
      aria-hidden={props.loading}
    >
      {props.children}
    </div>
  );
}

export interface AtlasLoadingLoadersProps {
  /**
   * Les éléments `<AtlasLoading.Loader>` (markup additionnel autorisé)
   */
  children: React.ReactNode;
  /**
   * Le type de balise souhaité
   */
  tag?: keyof JSX.IntrinsicElements;
  /**
   * Les classes à ajouter
   */
  className?: string;
}

/**
 * Un groupe d'indicateurs de chargement. Ce conteneur permet d'englober
 * plusieurs `<AtlasLoading.Loader>` dans un élément quelconque
 * (div, button...), par exemple pour créer un squelette de chargement plus
 * complexe.
 * Il est par défaut inivisible. Il apparaît lorsqu'il est inclus dans un
 * composant `<AtlasLoading>` actif.
 * Lorsqu'il apparaît, tous les éléments de même niveau suivant dans le DOM sont
 * cachés.
 */
AtlasLoading.Loaders = (props: AtlasLoadingLoadersProps) => {
  const Tag = props.tag ?? "div";

  return (
    <Tag className={classNames("atlas-loading__loaders", props.className)}>
      {props.children as any}
    </Tag>
  );
};

/**
 * Un indicateur de chargement. Ils sont par défaut invisibles.
 * Ils apparaissent lorsqu'ils sont contenus dans un composant `<AtlasLoading>`
 * actif.
 * Lorsqu'ils apparaissent, tous les éléments de même niveau suivant dans le DOM
 * sont cachés.
 */
AtlasLoading.Loader = () => {
  return <span className="atlas-loading__loader"></span>;
};
