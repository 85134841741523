import {
  AtlasIcon,
  AtlasIconButton,
  AtlasUserButtonProps,
  useForwardedRef,
  useMedia,
} from "atlas-ds";
import { ForwardedRef, forwardRef, RefObject, useState } from "react";

export interface AtlasNavProps {
  /**
   * Le bouton de connexion ou d'ouverture du menu utilisateur
   */
  userButton: React.ReactElement<AtlasUserButtonProps>;
  /**
   * Des éléments supplémentaires à ajouter à la suite des liens communs
   */
  additionalItems?: React.ReactNode[];
  /**
   * Une référence vers le menu, si un bouton d'ouverture doit y être lié
   */
  menuRef?: RefObject<HTMLDialogElement>;
}

/**
 * Le menu de navigation Atlas, commun à l'ensemble des sites
 */
export function AtlasNav(props: AtlasNavProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Open menu and observe when it closes
  const onClick = () => {
    if (props.menuRef?.current) {
      props.menuRef.current.showModal();
      setIsMenuOpen(true);

      const observer = new MutationObserver((mutations) =>
        mutations.forEach((mutation) => {
          if (mutation.attributeName === "open") {
            if (!props.menuRef?.current?.hasAttribute("open")) {
              setIsMenuOpen(false);
              observer.disconnect();
            }
          }
        })
      );

      observer.observe(props.menuRef.current, { attributes: true });
    }
  };

  return (
    <nav className="atlas-nav">
      {props.menuRef && (
        <div className="atlas-nav__menu">
          <AtlasIconButton
            ariaLabel="Menu principal"
            ariaExpanded={isMenuOpen}
            onClick={onClick}
          >
            <AtlasIcon name="menu" />
          </AtlasIconButton>
        </div>
      )}

      <ul className="atlas-nav__links">
        <li>
          <a
            href="https://www.opco-atlas.fr/actualites.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AtlasIcon name="news" size="xs" />
            Actualités
          </a>
        </li>
        <li>
          <a
            href="https://www.opco-atlas.fr/agenda.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AtlasIcon name="calendar" size="xs" />
            Agenda
          </a>
        </li>
      </ul>

      {props.additionalItems}

      {props.userButton}
    </nav>
  );
}

export interface AtlasNavNotificationsProps {
  /**
   * Le nombre de notifications
   */
  count: number;
  /**
   * Une référence vers le panneau des notifications
   */
  notificationsRef: RefObject<HTMLDialogElement>;
}

AtlasNav.Notifications = forwardRef(
  (props: AtlasNavNotificationsProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const isMediumScreen = useMedia("medium");
    const [isOpen, setIsOpen] = useState(false);
    const innerRef = useForwardedRef(ref);

    // Open notifications panel and observe when it closes
    const onClick = () => {
      if (props.notificationsRef.current) {
        if (isOpen) {
          props.notificationsRef.current.close();
        } else {
          // Notifications panel is modal on small devices only
          if (isMediumScreen) {
            props.notificationsRef.current.show();
          } else {
            props.notificationsRef.current.showModal();
          }

          setIsOpen(true);

          const observer = new MutationObserver((mutations) =>
            mutations.forEach((mutation) => {
              if (mutation.attributeName === "open") {
                if (!props.notificationsRef?.current?.hasAttribute("open")) {
                  setIsOpen(false);
                  observer.disconnect();
                }
              }
            })
          );

          observer.observe(props.notificationsRef.current, {
            attributes: true,
          });
        }
      }
    };

    return (
      <button
        ref={innerRef}
        type="button"
        className="atlas-nav__notificationsCta"
        onClick={onClick}
        aria-controls={
          props.notificationsRef.current?.getAttribute("id") ?? undefined
        }
        aria-expanded={isOpen}
        // Prevent button to be announced as open when it's not the case
        aria-hidden={isOpen ? "true" : undefined}
      >
        <AtlasIcon name="comment" size="xs" />
        {props.count > 0 && (
          <span className="atlas-nav__notificationsCount">{props.count}</span>
        )}
        Notifications
      </button>
    );
  }
);
