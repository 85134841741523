import {
  AtlasIcon,
  AtlasIconButton,
  AtlasInfoType,
  AtlasLinkProps,
} from "atlas-ds";
import classNames from "classnames";
import { MouseEventHandler, useEffect, useRef, useState } from "react";

export interface AtlasMessagesProps {
  /**
   * Les toasts individuels
   */
  children: React.ReactElement<AtlasMessagesMessageProps>[];
}

/**
 * Le conteneur principal des messages affichés sur l'application.
 * Tous doivent être inclus dans ce conteneur.
 * Deux types existent :
 * - les messages classiques, présent dès l'ouverture de la page
 * - les messages toasts, survenant durant la vie de la page et éphémères
 */
export function AtlasMessages(props: AtlasMessagesProps) {
  return (
    <dialog
      open
      className="atlas-messages"
      aria-label="Activité de l'application"
      tabIndex={-1} // Prevent firefox to focus the dialog itself
    >
      <div className="atlas-messages__inner">{props.children}</div>
    </dialog>
  );
}

const getLink = (link?: React.ReactElement<AtlasLinkProps>) =>
  link
    ? {
        ...link,
        props: {
          level: 3,
          icon: "arrow-right",
          ...link.props,
        },
      }
    : undefined;

export type AtlasMessagesType = AtlasInfoType;

export interface AtlasMessagesMessageProps {
  /**
   * Le titre du message
   */
  label: string;
  /**
   * Le contenu du message
   */
  children: string;
  /**
   * L'action à éxécuter à la fermeture du message
   */
  onClose: MouseEventHandler<HTMLButtonElement>;
  /**
   * Un lien lié au message
   */
  link?: React.ReactElement<AtlasLinkProps>;
  /**
   * Le type d'information
   */
  type?: AtlasMessagesType;
}

/**
 * Un message classique.
 * En opposition à un message toast, un message classique est déjà présent sur
 * la page à son ouverture.
 * Il ne se ferme pas automatiquement et doit être fermé via un bouton d'action.
 */
AtlasMessages.Message = (props: AtlasMessagesMessageProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isClosing, setIsClosing] = useState(false);

  const onClose = () => {
    setIsClosing(true);
    setTimeout(props.onClose, 250);
  };

  return (
    <div
      ref={ref}
      className={classNames(
        "atlas-messages__message",
        `u-atlas-color-${props.type ?? "info"}`,
        {
          "atlas-messages__message--isClosing": isClosing,
        }
      )}
    >
      <div className="atlas-messages__messageInner">
        <div className="atlas-messages__messageHeader">{props.label}</div>
        <p className="atlas-messages__messageContent">{props.children}</p>
        {getLink(props.link)}

        <div className="atlas-messages__close">
          <AtlasIconButton
            ariaLabel={`Fermer l'information ${props.label}`}
            onClick={onClose}
          >
            <AtlasIcon name="close" size="s" />
          </AtlasIconButton>
        </div>
      </div>
    </div>
  );
};

export interface AtlasMessagesToastProps extends AtlasMessagesMessageProps {}

/**
 * Un message toast.
 * En opposition à un message classique, un message toast apparaît au cours de
 * l'existence de la page pour informer d'une opération réussie ou d'un
 * problème.
 * Il est éphémère et se ferme automatiquement.
 * Il est annoncé aux technologies d'assistance.
 */
AtlasMessages.Toast = (props: AtlasMessagesToastProps) => {
  const timeout = useRef<any>(null);
  const [isClosing, setIsClosing] = useState(false);
  const [statusContent, setStatusContent] = useState<JSX.Element>();
  const [alertContent, setAlertContent] = useState<JSX.Element>();
  const closingDelay = 5000;

  const onClose = () => {
    setIsClosing(true);
    setTimeout(props.onClose, 250);
  };

  const closeAfterDelay = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(onClose, closingDelay);
  };

  const clearClosing = () => {
    clearTimeout(timeout.current);
  };

  useEffect(() => {
    closeAfterDelay();

    return () => clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    const content = (
      <>
        <div className="atlas-messages__messageHeader">
          <AtlasIcon name={props.type ?? "info"} size="xs" />
          {props.label}
        </div>
        <p className="atlas-messages__messageContent">{props.children}</p>
        <div onClick={onClose}>{getLink(props.link)}</div>
      </>
    );

    // Small delay to ensure the live region is picked up by screen readers
    setTimeout(() => {
      if (props.type && ["warning", "error"].includes(props.type)) {
        setAlertContent(content);
        setStatusContent(<></>);
      } else {
        setStatusContent(content);
        setAlertContent(<></>);
      }
    }, 100);
  }, [props.type, props.label, props.children, props.link]);

  return (
    <div
      onMouseEnter={clearClosing}
      onMouseLeave={closeAfterDelay}
      className={classNames(
        "atlas-messages__message",
        "atlas-messages__message--toast",
        `u-atlas-color-${props.type ?? "info"}`,
        {
          "atlas-messages__message--isClosing": isClosing,
        }
      )}
    >
      {/* This cannot be done with a single div and a dynamic role attribute
      because screen readers rely on stable elements for live regions. */}
      <div className="atlas-messages__messageInner" role="status">
        {statusContent}
      </div>
      <div className="atlas-messages__messageInner" role="alert">
        {alertContent}
      </div>
    </div>
  );
};
