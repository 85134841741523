import { AtlasButton, AtlasLoading } from "atlas-ds";
import classNames from "classnames";
import { useId } from "react";

export interface AtlasValuesProps {
  /**
   * Les valeurs et lignes de valeurs
   **/
  children: React.ReactNode;
  /**
   * Le nom de l'entité représentée
   */
  label?: string;
  /**
   * Le niveau d'importance visuelle du label (1 par défaut)
   */
  labelLevel?: 1 | 2;
  /**
   * Changer le nombre d'élément par ligne (2 par défaut)
   */
  columnsCount?: 1 | 2 | 3 | 4;
}

/**
 * Un groupe de valeurs
 */
export function AtlasValues(props: AtlasValuesProps) {
  return (
    <div
      style={
        {
          "--atlas-values-columns-count": props.columnsCount ?? 2,
        } as React.CSSProperties
      }
      className="atlas-values"
    >
      {"label" in props && (
        <p
          className={classNames("atlas-values__label", {
            "atlas-values__label--level2": props.labelLevel === 2,
          })}
        >
          <AtlasLoading.Loader />
          <span>{props.label}</span>
        </p>
      )}

      <dl className="atlas-values__list">{props.children}</dl>
    </div>
  );
}

export interface AtlasValuesValueProps {
  /**
   * Le label
   */
  label: string;
  /**
   * La valeur
   */
  children: React.ReactNode;
  /**
   * Étaler la valeur sur toute la largeur
   */
  full?: boolean;
  /**
   * Placer le label et la valeur sur la même ligne, si possible.
   * Éviter d'utiliser uniquement pour gagner de la place.
   * Réserver pour pour des cas particuliers (comme une série de montants qui
   * seront ainsi alignés à droite).
   * Cette propriété est automatiquement active lorsqu'il y a des sous-valeurs
   * (children).
   */
  inline?: boolean;
  /**
   * La valeur sous forme de texte et/ou html, telle qu'elle pourra être copiée
   * dans le presse-papier grâce à un bouton apparaissant au survol
   */
  copyable?: {
    text?: string;
    html?: string;
  };
  /**
   * Une fonction à éxécuter après le copiage de la valeur
   */
  onCopy?: Function;
}

/**
 * Une valeur associée à un label
 */
AtlasValues.Value = (props: AtlasValuesValueProps) => {
  const onCopy = () => {
    navigator.clipboard
      .write([
        new ClipboardItem({
          ...(props.copyable?.text
            ? {
                ["text/plain"]: new Blob([props.copyable?.text], {
                  type: "text/plain",
                }),
              }
            : {}),
          ...(props.copyable?.html
            ? {
                ["text/html"]: new Blob([props.copyable?.html], {
                  type: "text/html",
                }),
              }
            : {}),
        }),
      ])
      .then(
        () => {
          if (props.onCopy) {
            props.onCopy();
          }
        },
        () => {
          console.error("Could not copy value:", props.copyable);
        }
      );
  };

  return (
    <>
      <div
        className={classNames("atlas-values__value", {
          "atlas-values__value--full": props.full,
          "atlas-values__value--inline": props.inline,
        })}
        tabIndex={props.copyable ? 0 : undefined}
      >
        <dt className="atlas-values__valueLabel">
          {props.label}

          {props.copyable && (
            <div className="atlas-values__copy">
              <AtlasButton
                icon="copy"
                level={4}
                onClick={onCopy}
                ariaLabel={`Copier ${props.label}`}
              >
                Copier
              </AtlasButton>
            </div>
          )}
        </dt>

        <dd className="atlas-values__valueContent">
          <AtlasLoading.Loader />

          <div>{props.children}</div>
        </dd>
      </div>
    </>
  );
};

export interface AtlasValuesSubvalueProps {
  /**
   * Le label
   */
  label: string;
  /**
   * La valeur
   */
  children: React.ReactNode;
}

AtlasValues.Subvalue = (props: AtlasValuesSubvalueProps) => {
  return (
    <div className="atlas-values__subvalue">
      <dt className="atlas-values__subvalueLabel">
        {/* <AtlasLoading.Loader /> */}
        {props.label}
      </dt>

      <dd className="atlas-values__subvalueContent">{props.children}</dd>
    </div>
  );
};

export interface AtlasValuesUpdateProps {
  /**
   * La valeur précédente
   */
  before?: string;
  /**
   * La nouvelle valeur
   */
  children?: string;
  /**
   * Le texte à afficher en cas de champ vide
   */
  emptyValueLabel?: string;
}

/**
 * Une valeur mise à jour.
 * Utiliser au sein de AtlasValues.Value
 */
AtlasValues.Update = (props: AtlasValuesUpdateProps) => {
  const oldId = useId();
  const newId = useId();

  return (
    <>
      <p id={oldId} aria-hidden="true" className="atlas-values__updateLabel">
        Ancienne valeur
      </p>
      <del
        aria-describedby={oldId}
        className={classNames({ "atlas-values__empty": !props.before })}
      >
        {props.before ?? props.emptyValueLabel ?? "Champ vide"}
      </del>
      <p id={newId} aria-hidden="true" className="atlas-values__updateLabel">
        Nouvelle valeur
      </p>
      <ins
        aria-describedby={newId}
        className={classNames({ "atlas-values__empty": !props.children })}
      >
        {props.children ?? props.emptyValueLabel ?? "Champ vide"}
      </ins>
    </>
  );
};
