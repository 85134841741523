import { AtlasLoading, AtlasValuesProps } from "atlas-ds";
import { useEffect, useRef, useState } from "react";

/**
 * Une option sélectionnable
 */
export interface AtlasOptionsItem {
  /**
   * L'identifiant de l'option
   */
  id: string;
  /**
   * Le contenu de l'option.
   * Généralement une simple string.
   * Peut accueillir des contenus complexes (ex: AtlasValues).
   */
  content: React.ReactNode;
  /**
   * L'action à éxécuter à la sélection de cette option
   */
  onSelect: () => void;
}

/**
 * Un groupe d'options
 */
export interface AtlasOptionsGroup {
  /**
   * L'identifiant du groupe
   */
  id: string;
  /**
   * Le nom affiché du groupe
   */
  label: string;
  /**
   * Les options contenues dans le groupe
   */
  options: AtlasOptionsItem[];
}

export interface AtlasOptionsProps {
  /**
   * Les options.
   * Soit un tableau d'options, soit un tableau de groupe d'options.
   */
  options: AtlasOptionsItem[] | AtlasOptionsGroup[];
  /**
   * L'identifiant de l'élément servant de titre à la liste
   */
  ariaLabelledBy?: string;
  /**
   * Un squelette de chargement à afficher (c'est à dire une entité sans valeur)
   */
  loader?: React.ReactElement<AtlasValuesProps>;
}

/**
 * Une liste d'options sélectionnables
 */
export function AtlasOptions(props: AtlasOptionsProps) {
  const [search, setSearch] = useState("");

  const ref = useRef<HTMLUListElement>(null);
  const optionsRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearch(""), 1000);
    return () => clearTimeout(searchTimeout);
  }, [search]);

  const loaders = props.loader ? (
    <AtlasLoading.Loaders>
      {[1, 2, 3].map((index) => (
        <div key={`loader-${index}`} className="atlas-options__optionWrapper">
          <div className="atlas-options__option">{props.loader}</div>
        </div>
      ))}
    </AtlasLoading.Loaders>
  ) : (
    <></>
  );

  const onKeyDown = (event: React.KeyboardEvent) => {
    const $li = document.activeElement?.closest("li");

    switch (event.key) {
      case "ArrowDown":
        if ($li?.nextElementSibling) {
          event.preventDefault();
          moveFocus($li.nextElementSibling.firstElementChild as HTMLElement);
        }
        break;
      case "ArrowUp":
        if ($li?.previousElementSibling) {
          event.preventDefault();
          moveFocus(
            $li.previousElementSibling.firstElementChild as HTMLElement
          );
        }
        break;
      default:
        doSearch(event.key);
    }
  };

  const moveFocus = (to: HTMLElement) => {
    to.focus();
    to.scrollIntoView({ block: "center" });
  };

  const doSearch = (key: string) => {
    const isValidSearch = key.length === 1 && key.match(/[a-zA-Z0-9]/);

    if (isValidSearch) {
      optionsRef.current.some((button) => {
        if (button?.textContent?.toLowerCase().startsWith(search + key)) {
          setSearch(search + key);
          button.focus();

          return true;
        }
      });
    }
  };

  const onOptionKeydown = (
    event: React.KeyboardEvent<HTMLElement>,
    option: AtlasOptionsItem
  ) => {
    if (event.code === "Enter" || event.code === "Space") {
      event.preventDefault();
      option.onSelect();
    }
  };

  const getOption = (option: AtlasOptionsItem, index: number): JSX.Element => {
    return (
      <li key={option.id} className="atlas-options__optionWrapper">
        <div
          role="option"
          className="atlas-options__option"
          tabIndex={0}
          onClick={(option as AtlasOptionsItem).onSelect}
          onKeyDown={(event) => onOptionKeydown(event, option)}
          ref={(option) => {
            if (option) {
              optionsRef.current[index] = option;
            }
          }}
        >
          {(option as AtlasOptionsItem).content}
        </div>
      </li>
    );
  };

  // Prevent the whole component to be focused (which results in screen reader
  // reading the whole content). It will still be accessible because it contains
  // elements focusable (tabIndex=0).
  const tabIndex = -1;

  if (!props.options.length) {
    return <div className="atlas-options">{loaders}</div>;
  } else if ("content" in props.options[0]) {
    return (
      <ul
        role="listbox"
        ref={ref}
        className="atlas-options"
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
        aria-labelledby={props.ariaLabelledBy}
      >
        {props.options.map((option, index) =>
          getOption(option as AtlasOptionsItem, index)
        )}
      </ul>
    );
  } else {
    let index = 0;

    return (
      <div className="atlas-options" tabIndex={tabIndex}>
        {loaders}

        <ul role="listbox" ref={ref} aria-labelledby={props.ariaLabelledBy}>
          {props.options.map((group) => (
            <li key={group.id}>
              <ul role="group">
                <li
                  role="presentation"
                  id={group.id}
                  className="atlas-options__groupLabel"
                >
                  {(group as AtlasOptionsGroup).label}
                </li>

                {(group as AtlasOptionsGroup).options.map((option) =>
                  getOption(option as AtlasOptionsItem, ++index)
                )}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
