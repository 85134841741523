import { AtlasBreadcrumbProps, AtlasLoading } from "atlas-ds";
import classNames from "classnames";

export interface AtlasPageHeaderProps {
  /**
   * Le titre de la page
   */
  children: React.ReactNode;
  /**
   * Un composant de fil d'ariane
   */
  breadcrumb?: React.ReactElement<AtlasBreadcrumbProps>;
  /**
   * Une ou plusieurs actions contextuelles à la page
   */
  actions?: React.ReactNode;
  /**
   * Faire suivre le header lors du scroll
   */
  sticky?: boolean;
  /**
   * Un contenu additionnel (optionnel)
   */
  additionalContent?: React.ReactNode;
}

/**
 * Une en-tête principale de page, éventuellement accompagné par d'autres
 * éléments de navigation
 */
export function AtlasPageHeader(props: AtlasPageHeaderProps) {
  return (
    <div
      className={classNames("atlas-pageHeader", {
        "atlas-pageHeader--sticky": props.sticky,
      })}
    >
      {/* Keep this breadcrumb div, even empty, for space consistency across pages */}
      <div className="atlas-pageHeader__breadcrumb">{props.breadcrumb}</div>
      <div className="atlas-pageHeader__titleAndActions">
        {props.children}
        {props.actions && (
          <div className="atlas-pageHeader__actions">
            <AtlasLoading.Loader />
            <AtlasLoading.Loader />
            <AtlasLoading.Loader />
            {props.actions}
          </div>
        )}
      </div>
      {props.additionalContent && (
        <div className="atlas-pageHeader__content">
          {props.additionalContent}
        </div>
      )}
    </div>
  );
}
