import {
  AtlasMessagesProps,
  AtlasNavProps,
  AtlasPageHeaderProps,
  AtlasRgpdProps,
  CampusFooterProps,
  CampusHeaderProps,
  CampusIntroProps,
  CampusSearchProps,
} from "atlas-ds";

export interface CampusTemplateProps {
  /**
   * La barre de navigation
   */
  topNav: React.ReactElement<AtlasNavProps>;
  /**
   * Le header
   */
  header: React.ReactElement<CampusHeaderProps>;
  /**
   * Le contenu de la page
   */
  children: React.ReactNode;
  /**
   * Le footer
   */
  footer: React.ReactElement<CampusFooterProps>;
  /**
   * Les informations rgpd
   */
  rgpd: React.ReactElement<AtlasRgpdProps>;
  /**
   * Un composant d'introduction
   */
  intro?: React.ReactElement<CampusIntroProps>;
  /**
   * Le composant de recherche
   */
  search?: React.ReactElement<CampusSearchProps>;
  /**
   * Un composant de titre de la page
   */
  pageHeader?: React.ReactElement<AtlasPageHeaderProps>;
  /**
   * Ne pas inclure le contenu dans un conteneur restreint
   */
  full?: boolean;
  /**
   * Une liste de toasts à présenter
   */
  toasts?: React.ReactElement<AtlasMessagesProps>;
}

/**
 * Le template principal de l'application
 */
export function CampusTemplate(props: CampusTemplateProps) {
  const pageContent = (
    <>
      {props.pageHeader}
      {props.children}
    </>
  );

  return (
    <div className="campus-template">
      <header>
        {props.topNav}
        {props.header}
      </header>
      {props.toasts && (
        <div className="campus-template__toasts">{props.toasts}</div>
      )}
      {props.search}
      <main id="main">
        {props.intro && (
          <div className="campus-template__intro">{props.intro}</div>
        )}
        {props.full ? (
          pageContent
        ) : (
          <div className="campus-template__content">{pageContent}</div>
        )}
      </main>
      {props.footer}
      {props.rgpd}
    </div>
  );
}
