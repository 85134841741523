import { AtlasButton, AtlasHeading, AtlasTagsProps } from "atlas-ds";
import { MouseEventHandler } from "react";

export interface AtlasResultsHeaderProps {
  /**
   * Le nombre de résultats
   */
  count: number;
  /**
   * La liste des filtres actifs
   */
  filters?: React.ReactElement<AtlasTagsProps>;
  /**
   * Action à exécuter lorsque les filtres sont retirés
   */
  onResetFilters?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Un composant permettant d'effectuer des actions liées aux résultat
   * (tri, ajout...)
   */
  actions?: React.ReactNode;
  /**
   * Si cette propriété est fournie, sa valeur remplacera le label automatique
   * (aucun résultat, X résultats...)
   */
  resultsLabel?: string;
}

/**
 * Composant de présentation de résultats : nombre d'éléments trouvés, filtres
 * appliqués, options de tri...
 */
export function AtlasResultsHeader(props: AtlasResultsHeaderProps) {
  return (
    <div className="atlas-resultsHeader">
      <div className="atlas-resultsHeader__main">
        <div className="atlas-resultsHeader__count" aria-live="polite">
          <AtlasHeading tag="h2" size="m">
            {props.resultsLabel ||
              (props.count === 0
                ? "Aucun résultat"
                : `${props.count} résultat${props.count > 1 ? "s" : ""}`)}
          </AtlasHeading>
        </div>

        {props.filters && props.onResetFilters && (
          <div className="atlas-resultsHeader__resetFilters">
            <AtlasButton icon="filter" level={4} onClick={props.onResetFilters}>
              Réinitialiser les filtres
            </AtlasButton>
          </div>
        )}

        {props.actions && (
          <div className="atlas-resultsHeader__actions">{props.actions}</div>
        )}
      </div>

      {props.filters && (
        <div className="atlas-resultsHeader__filters">{props.filters}</div>
      )}
    </div>
  );
}
