import React, { useRef } from "react";

export interface AtlasRgpdProps {
  /**
   * Le label de l'information destinée aux technologies d'assistance.
   * Il s'agit d'une courte introduction au contenu réel.
   */
  ariaLabel: string;
  /**
   * Le contenu de l'information
   */
  children: React.ReactNode;
  /**
   * Action à éxécuter à la fermeture, en plus de la disparition elle-même
   */
  onClose?: () => void;
  /**
   * Un lien à afficher après le contenu
   */
  link?: {
    href: string;
    label: string;
    ariaLabel?: string;
    target?: string;
  };
}

/**
 * Information flottante relative au traitement des données.
 */
export function AtlasRgpd(props: AtlasRgpdProps) {
  const ref = useRef<HTMLDialogElement>(null);

  const close = () => {
    if (props.onClose) {
      props.onClose();
    }

    ref.current?.close();
  };

  return (
    <dialog
      tabIndex={-1} // Prevent firefox to focus the dialog itself
      ref={ref}
      className="atlas-rgpd"
      aria-label={props.ariaLabel}
      open
    >
      {props.children}

      <div className="atlas-rgpd__actions">
        {props.link && (
          <a
            href={props.link.href}
            target={props.link.target ?? undefined}
            aria-label={props.link.ariaLabel ?? undefined}
          >
            {props.link.label}
          </a>
        )}

        <button type="button" onClick={close}>
          Fermer
        </button>
      </div>
    </dialog>
  );
}
