// This must match Medias.mixins.scss
const medias = {
  medium: "(min-width: 43.75em)",
  large: "(min-width: 70em)",
  wide: "(min-width: 96rem)",
};

import { useEffect, useState } from "react";

export function useMedia(size: keyof typeof medias) {
  const [matches, setMatches] = useState(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia(medias[size]);

    mediaQuery.addEventListener("change", (event: MediaQueryListEvent) =>
      setMatches(event.matches)
    );

    setMatches(mediaQuery.matches);
  }, []);

  return matches;
}
